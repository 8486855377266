export default [
  {
    header: 'Start for free',
    body: 'No initial investment needed. You can first see if this is the right off for you',
    icon: 'money_off',
  },
  {
    header: "You won't forget it",
    body: 'We guarantee that the knowledge you earn will stay with you forever',
    icon: 'psychology',
  },
  {
    header: 'Easy on your time',
    body: '20 minutes a day is all the commitement we need from you',
    icon: 'query_builder',
  },
];
